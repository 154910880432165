import './App.css';
import React from 'react';

//import Scss
import './assets/scss/themes.scss';

// Import routes
import RoutesList from './routes/RoutesList';

function App() {
  return (
    <React.Fragment>
        <RoutesList />
    </React.Fragment>
  );
}

export default App;
