import React, { Suspense, lazy } from "react"
import { BrowserRouter as Router, Route, Routes } from "react-router-dom"
import PublicRouterOutlet from "./PublicRouterOutlet"
import { adminRoutes, authRoutes, dashboardRoutes, expensesRoutes, invoicesRoutes, ledgerRoutes, merchantRoutes, publicRoutes, transactionRoutes, userRoutes } from "./allRoutes"
import AuthRouterOutlet from "./AuthRouterOutlet"
import Loader from "../Components/Partials/Loader"


const MainLayout = lazy(() => import("../Components/Layouts/MainLayout"))
const PublicLayout = lazy(() => import("../Components/Layouts/PublicLayout"))
const Page404 = lazy(() => import("../pages/Error/Page404"))

const RoutesList = () => {
    return (
        <React.Fragment>
            <Router>
                <Suspense fallback={<Loader showLoader={true} pos={'fixed'} />}>
                    <Routes>
                        <Route path="/user" element={<AuthRouterOutlet layout={MainLayout} authorizeLevel={[1,2,3,4]} />}>
                            {userRoutes && React.Children.toArray(userRoutes.map((item) => {
                                return <Route path={item.path} element={item.component} />
                            }))}
                        </Route>
                        <Route path="/dashboard" element={<AuthRouterOutlet layout={MainLayout} authorizeLevel={[1,2,3,4]} />}>
                            {dashboardRoutes && React.Children.toArray(dashboardRoutes.map((item) => {
                                return <Route path={item.path} element={item.component} />
                            }))}
                        </Route>

                        <Route path="/expenses" element={<AuthRouterOutlet layout={MainLayout} authorizeLevel={[1,2,3,4]} />}>
                            {expensesRoutes && React.Children.toArray(expensesRoutes.map((item) => {
                                return <Route path={item.path} element={item.component} />
                            }))}
                        </Route>
                        <Route path="/invoices" element={<AuthRouterOutlet layout={MainLayout} authorizeLevel={[1,2,3,4]} />}>
                            {invoicesRoutes && React.Children.toArray(invoicesRoutes.map((item) => {
                                return <Route path={item.path} element={item.component} />
                            }))}
                        </Route>
                        <Route path="/transactions" element={<AuthRouterOutlet layout={MainLayout} authorizeLevel={[1,2,3,4]} />}>
                            {transactionRoutes && React.Children.toArray(transactionRoutes.map((item) => {
                                return <Route path={item.path} element={item.component} />
                            }))}
                        </Route>
                        <Route path="/reports" element={<AuthRouterOutlet layout={MainLayout} authorizeLevel={[1,2,3,4]} />}>
                            {ledgerRoutes && React.Children.toArray(ledgerRoutes.map((item) => {
                                return <Route path={item.path} element={item.component} />
                            }))}
                        </Route>
                        
                        <Route path="/admin" element={<AuthRouterOutlet layout={MainLayout} authorizeLevel={[1,2,3,4]} />}>
                            {adminRoutes && React.Children.toArray(adminRoutes.map((item) => {
                                return <Route path={item.path} element={item.component} />
                            }))}
                        </Route>

                        <Route path="/auth" element={<PublicRouterOutlet layout={PublicLayout} />}>
                            {authRoutes && React.Children.toArray(authRoutes.map((item) => {
                                return <Route path={item.path} element={item.component} />
                            }))}
                        </Route>
                        
                        <Route path="/" element={<PublicRouterOutlet layout={PublicLayout} />}>
                            {publicRoutes && React.Children.toArray(publicRoutes.map((item) => {
                                return <Route path={item.path} element={item.component} />
                            }))}
                            <Route path="*" element={<PublicLayout><Page404 /></PublicLayout>}></Route>
                        </Route>
                    </Routes>
                </Suspense>
            </Router>
        </React.Fragment>
    )
    
}

export default RoutesList
