import { lazy } from "react";
import { Navigate } from "react-router-dom";
// import AddExpense from "../pages/Expenses/AddExpense";

const Login = lazy(() => import("../pages/Authentication/Login"))
const LoginOTP = lazy(() => import("../pages/Authentication/LoginOTP"))
const Register = lazy(() => import("../pages/Authentication/Register"))
const ForgotPassword = lazy(() => import("../pages/Authentication/ForgotPassword"))
const ResetPassword = lazy(() => import("../pages/Authentication/ResetPassword"))
const LockScreen = lazy(() => import("../pages/Authentication/LockScreen"))

const Profile = lazy(() => import("../pages/User/Profile"))
const Dashboard = lazy(() => import("../pages/Dashboard/Dashboard"))
const AdminSetttings = lazy(() => import("../pages/admin/Settings"))
const Expenses = lazy(() => import("../pages/Expenses/Expenses"))
const AddExpense = lazy(() => import("../pages/Expenses/AddExpense"))
const Transactions = lazy(() => import("../pages/Transactions/Transactions"))
const Invoices = lazy(() => import("../pages/Invoices/Invoices"))
const BalanceSheet = lazy(() => import("../pages/Reports/BalanceSheet"))
const ProftLossReport = lazy(() => import("../pages/Reports/ProftLossReport"))

const authRoutes = [
    {path:"/auth/login", component: <Login />},
    {path:"/auth/otp", component: <LoginOTP />},
    {path:"/auth/signup", component: <Register />},
    {path:"/auth/forgotpassword", component: <ForgotPassword />},
    {path:"/auth/resetpassword/:token", component: <ResetPassword />},
    {path:"/auth/lockscreen", component: <LockScreen />},
]
const adminRoutes = [
    {path:"/admin/users", component: <AdminSetttings />},
    // {path:"/admin/requests", component: <AdminSetttings />},
    // {path:"/admin/groups", component: <AdminSetttings />},
    // {path:"/admin/vendors", component: <AdminSetttings />},
    {path:"/admin/projects", component: <AdminSetttings />},
    // {path:"/admin/budget", component: <AdminSetttings />},
    // {path:"/admin/banks", component: <AdminSetttings />},
    // {path:"/admin/categories", component: <AdminSetttings />},
    {path:"/admin/payment-methods", component: <AdminSetttings />},
    // {path:"/admin/roles", component: <AdminSetttings />},
    // {path:"/admin/tax-slabs", component: <AdminSetttings />},
    // {path:"/admin/reject-reasons", component: <AdminSetttings />},
    {path:"/admin", component: <Navigate to={"/admin/users"} />},
    {path:"*", component: <Navigate to={"/admin/users"} />},
]


const expensesRoutes = [
    {path:"/expenses/all", component: <Expenses />},
    // {path:"/expenses/personal", component: <Expenses />},
    // {path:"/expenses/approver", component: <Expenses />},
    // {path:"/expenses/finance", component: <Expenses />},
    {path:"/expenses/add", component: <AddExpense />},
    {path:"/expenses", component: <Navigate to={"/expenses/all"} />},
]
const invoicesRoutes = [
    // {path:"/invoices/list", component: <Invoices />},
    // {path:"/invoices", component: <Navigate to={"/invoices/list"} />},
]
const transactionRoutes = [
    // {path:"/transactions/personal", component: <Transactions />},
    // {path:"/transactions/finance", component: <Transactions />},
    // {path:"/transactions/logs", component: <Transactions />},
    // {path:"/transactions", component: <Navigate to={"/transactions/personal"} />},
]

const dashboardRoutes = [
    // {path:"/dashboard/personal", component: <Dashboard />},
    // {path:"/dashboard/approver", component: <Dashboard />},
    // {path:"/dashboard/finance", component: <Dashboard />},
    // {path:"/dashboard", component: <Navigate to={"/dashboard/personal"} />},
]
const userRoutes = [
    // {path:"/user/profile", component: <Profile />},
    // // {path:"/user/report", component: <Reports />},
    // {path:"/user", component: <Navigate to={"/user/profile"} />},
]

const ledgerRoutes = [
    {path:"/reports/balance-sheet", component: <BalanceSheet />},
    {path:"/reports/profit-loss", component: <ProftLossReport />},
    {path:"/reports", component: <Navigate to={"/reports/balance-sheet"} />},
]

// Authenticated routes

const publicRoutes = [
    {path:"/faq", component: <Navigate to={"/expenses/all"} />},
    {path:"/termscondition", component: <Navigate to={"/expenses/all"} />},
    {path:"/privacypolicy", component: <Navigate to={"/expenses/all"} />},
    {path:"/", component: <Navigate to={"/expenses/all"} />},
]

export { authRoutes, userRoutes, publicRoutes, adminRoutes, expensesRoutes, transactionRoutes, invoicesRoutes, dashboardRoutes, ledgerRoutes }